import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostWrapper from '../components/PostWrapper/PostWrapper';

const SecondPage = () => (
  <Layout>
    <SEO title="Blog" />
    <PostWrapper postCount={0} />
  </Layout>
)

export default SecondPage
