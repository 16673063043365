import React from 'react';
import { Link } from 'gatsby';
import './Post.css';
import AuthorIcon from '../../../assets/images/user.svg';
import TagIcon from '../../../assets/images/label.svg';
import DateIcon from '../../../assets/images/calendar.svg';
const slugify = require('@sindresorhus/slugify');

const Post = (props) => (
    <Link className='postLink'
        to={`/${slugify(props.title, {decamelize: false, customReplacements: [['&', '']]})}`}
    >
        <div className='Post__postWrapper' style={{background:`url(${props.image}) center/cover no-repeat`}}>
            <div className='Post__postBorder'>
                <h2 className='Post__postTitle'>{ props.title }</h2>
                <div className='Post__postInfo'>
                    <div className="postInfo__authorWrapper">
                        <img src={AuthorIcon} alt='author' className='postInfo__author' /><span className='postInfo__author'>{ props.author }{', '}</span>
                    </div> 
                    <div className="postInfo__tagWrapper">
                        <img src={TagIcon} alt='category' className='postInfo__tag' /><span className='postInfo__tag'>{ props.tag }{', '}</span>
                    </div>
                    <div className="postInfo__dateWrapper">
                        <img src={DateIcon} alt='date' /><span className='postInfo__date'>{ props.date }</span>
                    </div>
                    
                </div>
                <div className='Post__postContent'><span dangerouslySetInnerHTML={{ __html: props.content }} /></div>
            </div>
        </div>
    </Link>
);

export default Post;