import React from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from "gatsby";
import moment from 'moment';
import 'moment/locale/pl';
import { defaultImagesList } from '../../data/defaultImages';
import Post from './Post/Post';
import './PostWrapper.css';
        
const PostWrapper = ({postCount, blogHeader}) => {
  const data = useStaticQuery(graphql`
    query {
      blog {
        posts {
          createdAt
          id
          title
          autor {
            name
          }
          createDate
          categorie
          image {
            handle
          }
          content {
            html
          }
        }
      }
    }
  `)

  const sortedPosts = data.blog.posts.sort((a, b) => new Date(a.createDate ? a.createDate : a.createdAt) - new Date(b.createDate ? b.createDate : b.createdAt))

  return (
    <>
      <h1 className='PostWrapper__h1'>{blogHeader}</h1>
      <div className='PostWrapper__posts'>
        {sortedPosts.slice(postCount).reverse().map((post) => (
            <Post key={ post.id }
                title={ post.title }
                author={ post.autor.name }
                tag={ post.categorie }
                date={ moment(post.createDate ? post.createDate : post.createdAt).format("DD MMMM YYYY") + 'r.' }
                content={ post.content.html }
                image={ post.image !== null ? `https://media.graphcms.com/${post.image.handle}` : defaultImagesList[Math.floor(Math.random() * (11))].image }
            />
        ))}
      </div>
      <div className='PostWrapper__button' style={{display:(postCount === 0) ? ('none') : ('flex')}} >
        <Link to='blog' >Zobacz wszystko</Link>
      </div>
    </>    
  )
};

export default PostWrapper;